import {
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { 
  InsightPublicationsApi, 
  NewsPublicationsApi, 
  PodcastPublicationsApi, 
  VideoPublicationsApi, 
  AlternativeViewsPublicationsApi, 
  QuantMotionPicturePublicationsApi, 
  EtfMarketInsightPublicationsApi,
} from 'services/ApiService';
import { EditPublication } from '../editPublicationSlice';

export const getNewsPublication = createAsyncThunk(
  '@EDIT_PUBLICATION/FETCH_NEWS_PUBLICATION',
  async (id: number) => {
    return await NewsPublicationsApi.getNewsPublication(id) as EditPublication;
  },
);

export const getVideoPublication = createAsyncThunk(
  '@EDIT_PUBLICATION/FETCH_VIDEO_PUBLICATION',
  async (id: number) => {
    return await VideoPublicationsApi.getVideoPublication(id) as EditPublication;
  },
);

export const getInsightPublication = createAsyncThunk(
  '@EDIT_PUBLICATION/FETCH_INSIGHT_PUBLICATION',
  async (id: number) => {
    return await InsightPublicationsApi.getInsightPublication(id) as EditPublication;
  },
);


export const getPodcastPublication = createAsyncThunk(
    '@EDIT_PUBLICATION/FETCH_PODCAST_PUBLICATION',
    async (id: number) => {
      return await PodcastPublicationsApi.getPodcastPublication(id) as EditPublication;
    },
);

export const getAlternativeViewPublication = createAsyncThunk(
  '@EDIT_PUBLICATION/FETCH_ALTERNATIVE_VIEW_PUBLICATION',
  async (id: number) => {
    return await AlternativeViewsPublicationsApi.getAlternativeViewsPublication(id) as EditPublication;
  },
);

export const getQuantMotionPicturePublication = createAsyncThunk(
  '@EDIT_PUBLICATION/FETCH_QUANT_MOTION_PICTURE_PUBLICATION',
  async (id: number) => {
    return await QuantMotionPicturePublicationsApi.getQuantMotionPicturePublication(id) as EditPublication;
  },
);

export const getEtfMarketInsightPublication = createAsyncThunk(
  '@EDIT_PUBLICATION/FETCH_ETF_MARKET_INSIGHT_PUBLICATION',
  async (id: number) => {
    return await EtfMarketInsightPublicationsApi.getEtfMarketInsightPublication(id) as EditPublication;
  },
);