import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { 
  PublicationNews, 
  PublicationInsight, 
  PublicationVideo, 
  PublicationPodcast, 
  PublicationQuantMotionPicture, 
  PublicationEtfMarketInsight,
} from 'services/ApiService';
import { uploadFile } from 'store/file/thunks/uploadFile';
import { RequestState, RequestStateById } from 'store/helpers';
import { handleGetEntityByIdThunk, handleThunk } from 'store/helpers/reducerHandlers';
import { 
  getInsightPublication, 
  getNewsPublication, 
  getPodcastPublication, 
  getVideoPublication, 
  getAlternativeViewPublication, 
  getQuantMotionPicturePublication, 
  getEtfMarketInsightPublication,
} from './thunks/getPublication';
import { 
  updateAlternativeViewPublication,
  updateEtfMarketInsightPublication, 
  updateInsightPublication, 
  updateNewsPublication, 
  updatePodcastPublication, 
  updateQuantMotionPicturePublication, 
  updateVideoPublication,
} from './thunks/updatePublication';

export type EditPublication = PublicationNews & PublicationInsight & PublicationVideo & PublicationPodcast & PublicationQuantMotionPicture & PublicationEtfMarketInsight;

export const editPublicationAdapter = createEntityAdapter<EditPublication>();

const initialState = {
  requestStateById: {} as RequestStateById<number>,
  requestState: 'idle' as RequestState,
  error: undefined,
};

type EditPublicationState = typeof initialState;

const invalidateOnUpdate = (state: EditPublicationState, action: { meta: { arg: { id: number } } }) => {
  return state.requestStateById[action.meta.arg.id] = 'didInvalidate';
};

export const editPublicationSlice = createSlice({
  name: '@EDIT_PUBLICATION',
  initialState: editPublicationAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: builder => {

    handleGetEntityByIdThunk(builder, editPublicationAdapter, getInsightPublication);
    handleGetEntityByIdThunk(builder, editPublicationAdapter, getVideoPublication);
    handleGetEntityByIdThunk(builder, editPublicationAdapter, getNewsPublication);
    handleGetEntityByIdThunk(builder, editPublicationAdapter, getPodcastPublication);
    handleGetEntityByIdThunk(builder, editPublicationAdapter, getAlternativeViewPublication);
    handleGetEntityByIdThunk(builder, editPublicationAdapter, getQuantMotionPicturePublication);
    handleGetEntityByIdThunk(builder, editPublicationAdapter, getEtfMarketInsightPublication);

    handleThunk(builder, updateVideoPublication, invalidateOnUpdate);
    handleThunk(builder, updateNewsPublication, invalidateOnUpdate);
    handleThunk(builder, updateInsightPublication, invalidateOnUpdate);
    handleThunk(builder, updatePodcastPublication, invalidateOnUpdate);
    handleThunk(builder, updateAlternativeViewPublication, invalidateOnUpdate);
    handleThunk(builder, updateQuantMotionPicturePublication, invalidateOnUpdate);
    handleThunk(builder, updateEtfMarketInsightPublication, invalidateOnUpdate);
    handleThunk(builder, uploadFile);
  },
});