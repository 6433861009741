
import { PublicationFormValues } from 'components/common/PublicationForm';
import {
  TranslatePublicationPodcastRequest,
  PodcastPublicationsApi,
  PublicationType,
} from 'services/ApiService';
import * as PublicationFormMapping from 'store/mapping/mapPublicationFormValues';
import { createAsyncThunkWithErrorPayload } from 'store/helpers';

export const translatePodcastPublication = createAsyncThunkWithErrorPayload('@TRANSLATE_PODCAST_PUBLICATION', (translatePublicationRequest: TranslatePublicationPodcastRequest) =>
  PodcastPublicationsApi.translatePodcastPublication(translatePublicationRequest));

export const translatePublication = (masterId: number, formValues: PublicationFormValues, coverImageFileId: number | undefined, transcriptFileId: number | undefined) => {
  const thunkFactory = {
    [PublicationType.Podcast]: () => translatePodcastPublication(
      PublicationFormMapping.getTranslatePodcastPublicationRequest(masterId, formValues, coverImageFileId, transcriptFileId),
    ),
    [PublicationType.News]: () => translatePodcastPublication(
      PublicationFormMapping.getTranslatePodcastPublicationRequest(masterId, formValues, coverImageFileId, transcriptFileId),
    ),
    [PublicationType.Insight]: () => translatePodcastPublication(
      PublicationFormMapping.getTranslatePodcastPublicationRequest(masterId, formValues, coverImageFileId, transcriptFileId),
    ),
    [PublicationType.AlternativeView]: () => translatePodcastPublication(
      PublicationFormMapping.getTranslatePodcastPublicationRequest(masterId, formValues, coverImageFileId, transcriptFileId),
    ),
    [PublicationType.Video]: () => translatePodcastPublication(
      PublicationFormMapping.getTranslatePodcastPublicationRequest(masterId, formValues, coverImageFileId, transcriptFileId),
    ),
    [PublicationType.QuantMotionPicture]: () => translatePodcastPublication(
      PublicationFormMapping.getTranslatePodcastPublicationRequest(masterId, formValues, coverImageFileId, transcriptFileId),
    ),
    [PublicationType.EtfMarketInsight]: () => translatePodcastPublication(
      PublicationFormMapping.getTranslatePodcastPublicationRequest(masterId, formValues, coverImageFileId, transcriptFileId),
    ),
  }[formValues.publicationType];
  return thunkFactory();
};