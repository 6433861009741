import { createSlice } from '@reduxjs/toolkit';
import { handleThunk } from 'store/helpers/reducerHandlers';
import { WithRequestState } from 'store/helpers/requestState';
import { 
  createInsightPublication, 
  createVideoPublication, 
  createNewsPublication, 
  createPodcastPublication, 
  createQuantMotionPicturePublication,
  createEtfMarketInsightPublication,
 } from './thunks/createPublication';
import { uploadFile } from 'store/file/thunks/uploadFile';

const initialState: WithRequestState = {
  requestState: 'idle',
};

export const createPublicationSlice = createSlice({
  name: '@CREATE_PUBLICATION',
  initialState,
  reducers: {},
  extraReducers: builder => {
    handleThunk(builder, createInsightPublication);
    handleThunk(builder, createVideoPublication);
    handleThunk(builder, createNewsPublication);
    handleThunk(builder, createPodcastPublication);
    handleThunk(builder, createQuantMotionPicturePublication);
    handleThunk(builder, createEtfMarketInsightPublication);
    handleThunk(builder, uploadFile);
  },
});