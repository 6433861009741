import { PublicationFormValues } from 'components/common/PublicationForm';
import {
  UpdatePublicationInsightRequest,
  UpdatePublicationNewsRequest,
  UpdatePublicationVideoRequest,
  InsightPublicationsApi,
  NewsPublicationsApi,
  PublicationType,
  VideoPublicationsApi,
  PodcastPublicationsApi,
  UpdatePublicationPodcastRequest,
  AlternativeViewsPublicationsApi,
  UpdateAlternativeViewsRequest,
  QuantMotionPicturePublicationsApi,
  UpdateQuantMotionPictureRequest,
  UpdateEtfMarketInsightRequest,
  EtfMarketInsightPublicationsApi,
} from 'services/ApiService';
import * as PublicationFormMapping from 'store/mapping/mapPublicationFormValues';
import { createAsyncThunkWithErrorPayload } from 'store/helpers';

export const updateVideoPublication = createAsyncThunkWithErrorPayload('@UPDATE_VIDEO_PUBLICATION', (updatePublicationRequest: UpdatePublicationVideoRequest) =>
  VideoPublicationsApi.updateVideoPublication(updatePublicationRequest));

export const updateInsightPublication = createAsyncThunkWithErrorPayload('@UPDATE_INSIGHT_PUBLICATION', (updatePublicationRequest: UpdatePublicationInsightRequest) =>
  InsightPublicationsApi.updateInsightPublication(updatePublicationRequest));

export const updateNewsPublication = createAsyncThunkWithErrorPayload('@UPDATE_NEWS_PUBLICATION', (updatePublicationRequest: UpdatePublicationNewsRequest) =>
  NewsPublicationsApi.updateNewsPublication(updatePublicationRequest));

export const updatePodcastPublication = createAsyncThunkWithErrorPayload('@UPDATE_PODCAST_PUBLICATION', (updatePublicationRequest: UpdatePublicationPodcastRequest) =>
  PodcastPublicationsApi.updatePodcastPublication(updatePublicationRequest));

export const updateAlternativeViewPublication = createAsyncThunkWithErrorPayload('@UPDATE_ALTERNATIVE_VIEW_PUBLICATION', (updatePublicationRequest: UpdateAlternativeViewsRequest) =>
  AlternativeViewsPublicationsApi.updateAlternativeViewsPublication(updatePublicationRequest));

export const updateQuantMotionPicturePublication = createAsyncThunkWithErrorPayload('@UPDATE_QUANT_MOTION_PICTURE_PUBLICATION', (updateQuantMotionPictureRequest: UpdateQuantMotionPictureRequest) =>
QuantMotionPicturePublicationsApi.updateQuantMotionPicturePublication(updateQuantMotionPictureRequest));

export const updateEtfMarketInsightPublication = createAsyncThunkWithErrorPayload('@UPDATE_ETF_MARKET_INSIGHT_PUBLICATION', (updateEtfMarketInsightRequest: UpdateEtfMarketInsightRequest) =>
  EtfMarketInsightPublicationsApi.updateEtfMarketInsightPublication(updateEtfMarketInsightRequest));

export const updatePublication = (id: number,
  publicationType: PublicationType,
  formValues: PublicationFormValues,
  coverImageFileId: number | undefined,
  transcriptFileId: number | undefined,
  quantImageFileId: number,
  etfImageFileId: number,
) => {
  const thunkFactory = {
    [PublicationType.News]: () => updateNewsPublication(
      PublicationFormMapping.getUpdateNewsPublicationRequest(id, formValues, coverImageFileId),
    ),
    [PublicationType.Insight]: () => updateInsightPublication(
      PublicationFormMapping.getUpdateInsightPublicationRequest(id, formValues, coverImageFileId),
    ),
    [PublicationType.Video]: () => updateVideoPublication(
      PublicationFormMapping.getUpdateVideoPublicationRequest(id, formValues, coverImageFileId),
    ),
    [PublicationType.Podcast]: () => updatePodcastPublication(
      PublicationFormMapping.getUpdatePodcastPublicationRequest(id, formValues, coverImageFileId, transcriptFileId),
    ),
    [PublicationType.AlternativeView]: () => updateAlternativeViewPublication(
      PublicationFormMapping.getUpdateAlternativeViewPublicationRequest(id, formValues, coverImageFileId),
    ),
    [PublicationType.QuantMotionPicture]: () => updateQuantMotionPicturePublication(
      PublicationFormMapping.getUpdateQuantMotionPicturePublicationRequest(id, formValues, coverImageFileId, quantImageFileId),
    ),
    [PublicationType.EtfMarketInsight]: () => updateEtfMarketInsightPublication(
      PublicationFormMapping.getUpdateEtfMarketInsightPublicationRequest(id, formValues, coverImageFileId, etfImageFileId),
    ),
  }[publicationType];
  return thunkFactory();
};