import { PublicationFormValues } from 'components/common/PublicationForm';
import {
  CreatePublicationInsightRequest,
  CreatePublicationNewsRequest,
  CreatePublicationPodcastRequest,
  CreatePublicationVideoRequest,
  CreateAlternativeViewsRequest,
  CreateQuantMotionPictureRequest,
  UpdatePublicationInsightRequest,
  UpdatePublicationNewsRequest,
  UpdatePublicationPodcastRequest,
  UpdatePublicationVideoRequest,
  UpdateAlternativeViewsRequest,
  UpdateQuantMotionPictureRequest,
  TranslatePublicationPodcastRequest,
  CreateEtfMarketInsightRequest,
  UpdateEtfMarketInsightRequest,
} from 'services/ApiService';

// Map Publication Form Values to API Requests

// Create

export const getCreateVideoPublicationRequest = (
  pub: PublicationFormValues, coverImageFileId: number | undefined): CreatePublicationVideoRequest =>
  ({ ...getCreatePublicationRequest(pub, coverImageFileId), videoProvider: pub.videoProvider, videoIdentifier: pub.videoIdentifier?.trim() });

export const getCreateNewsPublicationRequest = (
  pub: PublicationFormValues, coverImageFileId: number | undefined): CreatePublicationNewsRequest =>
  ({ ...getCreatePublicationRequest(pub, coverImageFileId), content: pub.content?.trim() });

export const getCreateInsightPublicationRequest = (
  pub: PublicationFormValues, coverImageFileId: number | undefined): CreatePublicationInsightRequest =>
  ({ ...getCreatePublicationRequest(pub, coverImageFileId), content: pub.content?.trim(), category: pub.category, esgSpotlight: pub.esgSpotlight });

export const getCreatePodcastPublicationRequest = (
  pub: PublicationFormValues,
  coverImageFileId: number | undefined,
  transcriptFileId: number | undefined,
): CreatePublicationPodcastRequest =>
  ({ ...getCreatePublicationRequest(pub, coverImageFileId), podcastId: pub.podcastId, podcastShowId: pub.podcastShowId, transcriptFileId });

export const getCreateAlternativeViewPublicationRequest = (
  pub: PublicationFormValues, coverImageFileId: number | undefined): CreateAlternativeViewsRequest =>
  ({ ...getCreatePublicationRequest(pub, coverImageFileId) });

export const getCreateQuantMotionPicturePublicationRequest = (
  pub: PublicationFormValues, coverImageFileId: number | undefined, quantImageFileId: number): CreateQuantMotionPictureRequest =>
  ({ ...getCreatePublicationRequest(pub, coverImageFileId), quantImageFileId });

export const getCreateEtfMarketInsightPublicationRequest = (
  pub: PublicationFormValues, coverImageFileId: number | undefined, etfImageFileId: number): CreateEtfMarketInsightRequest =>
  ({ ...getCreatePublicationRequest(pub, coverImageFileId), etfImageFileId });

// Update

export const getUpdateVideoPublicationRequest = (id: number,
  pub: PublicationFormValues, coverImageFileId: number | undefined): UpdatePublicationVideoRequest =>
  ({ ...getCreateVideoPublicationRequest(pub, coverImageFileId), id });

export const getUpdateNewsPublicationRequest = (id: number,
  pub: PublicationFormValues, coverImageFileId: number | undefined): UpdatePublicationNewsRequest =>
  ({ ...getCreateNewsPublicationRequest(pub, coverImageFileId), id });

export const getUpdateInsightPublicationRequest = (id: number,
  pub: PublicationFormValues, coverImageFileId: number | undefined): UpdatePublicationInsightRequest =>
  ({ ...getCreateInsightPublicationRequest(pub, coverImageFileId), id });

export const getUpdatePodcastPublicationRequest = (
  id: number,
  pub: PublicationFormValues,
  coverImageFileId: number | undefined,
  transcriptFileId: number | undefined,
): UpdatePublicationPodcastRequest =>
  ({ ...getCreatePodcastPublicationRequest(pub, coverImageFileId, transcriptFileId), id });

export const getUpdateAlternativeViewPublicationRequest = (id: number,
  pub: PublicationFormValues, coverImageFileId: number | undefined): UpdateAlternativeViewsRequest =>
  ({ ...getCreateAlternativeViewPublicationRequest(pub, coverImageFileId), id });

export const getUpdateQuantMotionPicturePublicationRequest = (id: number,
  pub: PublicationFormValues, coverImageFileId: number | undefined, quantImageFileId: number): UpdateQuantMotionPictureRequest =>
  ({ ...getCreateQuantMotionPicturePublicationRequest(pub, coverImageFileId, quantImageFileId), id });

export const getUpdateEtfMarketInsightPublicationRequest = (id: number,
  pub: PublicationFormValues, coverImageFileId: number | undefined, etfImageFileId: number): UpdateEtfMarketInsightRequest =>
  ({ ...getCreateEtfMarketInsightPublicationRequest(pub, coverImageFileId, etfImageFileId), id });

// Translate

export const getTranslatePodcastPublicationRequest = (
  masterId: number,
  pub: PublicationFormValues,
  coverImageFileId: number | undefined,
  transcriptFileId: number | undefined,
): TranslatePublicationPodcastRequest =>
  ({ ...getTranslatePublicationRequest(masterId, pub, coverImageFileId), podcastId: pub.podcastId, podcastShowId: pub.podcastShowId, transcriptFileId });

// Shared 

type CreatePublicationRequest = CreatePublicationVideoRequest | CreatePublicationInsightRequest | CreatePublicationInsightRequest
  | CreatePublicationPodcastRequest | CreateAlternativeViewsRequest | CreateQuantMotionPictureRequest | CreateEtfMarketInsightRequest;

const getCreatePublicationRequest = (
  {
    publicationDateUtc,
    title,
    summary,
    author,
    pageShortName,
    externalLink,
    rank,
    visibleOnHomePage,
    keywords,
    tags,
    publicationStatus,
    visibleOnWebSite,
  }: PublicationFormValues, coverImageFileId: number | undefined): CreatePublicationRequest => {

  return {
    title: title?.trim(),
    summary: summary?.trim(),
    author: author?.trim(),
    pageShortName: pageShortName?.trim(),
    externalLink: externalLink?.trim(),
    keywords: keywords?.trim(),
    tags: tags?.trim(),
    rank: rank || undefined,
    visibleOnHomePage,
    publicationStatus,
    coverImageFileId,
    publicationDateUtc,
    visibleOnWebSite,
  };
};

type TranslatePublicationRequest = TranslatePublicationPodcastRequest;

const getTranslatePublicationRequest = (
  masterId: number,
  {
    publicationDateUtc,
    title,
    summary,
    author,
    pageShortName,
    externalLink,
    rank,
    visibleOnHomePage,
    keywords,
    tags,
    publicationStatus,
    visibleOnWebSite,
  }: PublicationFormValues, coverImageFileId: number | undefined): TranslatePublicationRequest => {

  return {
    title: title?.trim(),
    summary: summary?.trim(),
    author: author?.trim(),
    pageShortName: pageShortName?.trim(),
    externalLink: externalLink?.trim(),
    keywords: keywords?.trim(),
    tags: tags?.trim(),
    rank: rank || undefined,
    visibleOnHomePage,
    publicationStatus,
    coverImageFileId,
    publicationDateUtc,
    masterId,
    visibleOnWebSite,
  };
};